<template>
  <div :class="{'meals-count-box': true, disabled: !active}">
    <router-link :to="mealCountRoute()">
      <div class="info">
        <img
          :src="scannedCount ?
            require(`@/assets/images/icon-food-containers-active.svg`) :
            require(`@/assets/images/icon-food-containers.svg`)"
          alt="Food Container"
          class="icon" />
        <div class="left">
          <h3>Meals count</h3>

          <p :class="{items: true, loading}">{{ scannedCount }} / {{ itemsCount }} <template v-if="scannedExtraCount > 0">({{ scannedExtraCount }} extra)</template> meals scanned</p>
        </div>
        <div class="right">
          <p class="percentage" v-if="!loading">
            {{ percentage(scannedCount, itemsCount) }}
          </p>
        </div>
      </div>
      <progress :max="itemsCount" :value="scannedCount" :class="progressClass(scannedCount / itemsCount)">
        {{ scannedCount }} / {{ itemsCount }}
      </progress>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      required: true,
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    scannedCount: {
      type: Number,
      required: false,
    },
    itemsCount: {
      type: Number,
      required: false,
    },
    scannedExtraCount: {
      type: Number,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
    },
  },
  methods: {

    mealCountRoute() {
      return {
        name: 'MealCount',
        params: {
          date: this.date,
        }
      };
    },

    percentage(a, b) {
      if (b == 0) return "0%";
      return `${Math.round((a / b) * 100)}%`;
    },

    progressClass(value) {
      if (value > 1) {
        return "complete";
      } else if (value <= 0) {
        return "zero";
      } else if (value == 1) {
        return "complete";
      } else {
        return "in-progress";
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.meals-count-box {
  margin-top: -4em;
  margin-bottom: 3em;
  background: #fff;
  border: 1px solid $gray300;
  position: relative;
  box-shadow: 0 1em 1em rgba(15, 13, 102, 0.04);

  &.disabled a {
    opacity: .4;
    pointer-events: none;
  }

  a {
    padding: 1em;
    color: $text-color;
    display: block;
  }

  .info {
    margin-bottom: 1em;
    justify-content: space-between;
    align-items: center;
    display: flex;

    .icon {
      margin: 0 1em 0 0;
      flex-shrink: 0;
      width: 2.5em;
    }
    .left {
      flex-grow: 1;
    }

    h3 {
      margin: 0;
      font-size: 1.2em;
    }
    .items {
      margin: 0 1em 0 0;
      color: $gray600;

      @include loading;
    }

    .percentage {
      margin: 0;
      font-size: 2em;
      font-weight: 600;
      line-height: 100%;
    }
  }
}
</style>
