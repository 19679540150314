<template>
  <Loading :loading="loading" />

  <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transitionName || 'slide-in'" mode="out-in">
      <div :key="route.fullPath">
        <component :is="Component" @toggleLoading="toggleLoading" />
      </div>
    </transition>
  </router-view>

  <info />

</template>

<style lang="scss">
</style>

<script>
export default {
  beforeCreate() {
    this.$store.commit('init');
  },
  beforeMount() {
  },
  mounted() {
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
  },
  methods: {
    toggleLoading(status) {
      this.loading = status;
    },
  },
  watch: {
    '$route': function() {
      document.title = "Packing Manager";
    }
  }
}
</script>


<style lang="scss" scoped>
.slide-in-enter-from { opacity: 0; transform: translateX(10em); }
.slide-in-enter-to { opacity: 1; }
.slide-in-enter-active { transition: all .15s ease-out; }

.slide-in-leave-to { transform: translateX(-10em); }
.slide-in-leave-active { opacity: 0; transition: all .15s ease-in; }


.slide-out-enter-from { opacity: 0; transform: translateX(-10em); }
.slide-out-enter-to { opacity: 1; }
.slide-out-enter-active { transition: all .15s ease-out; }

.slide-out-leave-to { transform: translateX(10em); }
.slide-out-leave-active { opacity: 0; transition: all .15s ease-in; }



.fade-enter-from { opacity: 0; }
.fade-enter-to { opacity: 1; }
.fade-enter-active { transition: all 1s ease-out; }
.fade-leave-active { opacity: 0; transition: all .15s ease-in; }
</style>
