<template>
  <h3>Log</h3>
  <FetchErrors
    :errors="errors"
    @tryAgain="fetchItems"
    v-if="errors.length" />

  <ul v-if="items.length" class="log-entries">
    <li v-for="(entry, idx) in items" :key="`entry-${idx}`" :class="`entry-type-${entry.entry_type}`">
      <p class="info">
        <span class="author" v-if="entry.created_by">{{ entry.created_by.username }}</span>
        <span class="date">{{ $formatDate(entry.created_at, "locale") }}</span>
      </p>

      <p class="message">{{ entry.message }}</p>
      <p class="additional-message" v-if="entry.additional_message">{{ entry.additional_message }}</p>

    </li>
  </ul>

  <Pagination
    @previous-page="previousPage"
    @next-page="nextPage"
    :page="page"
    :max-page="maxPage" />
</template>

<script>
import axios from '@/axios';

export default {
  emits: ['toggleLoading'],
  props: {
    bagID: null,
  },
  data() {
    return {
      page: 1,
      maxPage: 1,
      totalItems: 0,
      loading: false,
      cancelFetch: undefined,
      errors: [],
      items: [],
    }
  },
  mounted: function() {
    this.fetchItems();
  },
  methods: {
    previousPage: function() {
      this.page = Math.max(1, this.page - 1)
      this.fetchItems();
    },
    nextPage: function() {
      this.page = Math.min(this.maxPage, this.page + 1)
      this.fetchItems();
    },

    async fetchItems() {
      if (this.cancelFetch !== undefined) {
        this.cancelFetch();
      }

      this.errors = [];
      this.toggleLoading(true);

      let self = this;

      try {
        var {data} = await axios.get(`/api/bag/${this.bagID}/log/?page=${this.page}`, {cancelToken: new axios.CancelToken(function(c) { self.cancelFetch = c; })});

        if (data.success) {
          this.page = data.page;
          this.maxPage = data.max_page;
          this.totalItems = data.total_items;
          this.items = data.items;
          this.errors = [];
        } else {
          this.items = {};
          this.errors = data.errors;
        }
        this.toggleLoading(false);
      } catch(err) {
        if (!axios.isCancel(err)) {
          this.errors = [err['message'] || err];
        }
        this.toggleLoading(false);
      }
    },

    toggleLoading(status) {
      this.loading = status
      this.$emit('toggleLoading', status);
    },
  }
}
</script>

<style lang="scss">
.log-entries {
  margin: 1em -1em;
  padding: 0;
  list-style: none;
  background: #fefefe;
  border-bottom: 1px solid $border-color;

  li {
    padding: 1em 1em 1em 4em;
    background: transparent no-repeat 1em 1em;
    background-size: 2em 2em;
    border-top: 1px solid $border-color;

    &.entry-type-available { background-image: url("@/assets/images/icon-available.svg"); }
    &.entry-type-return    { background-image: url("@/assets/images/icon-return.svg"); }
    &.entry-type-dispatch  { background-image: url("@/assets/images/icon-dispatch.svg"); }
    &.entry-type-dead      { background-image: url("@/assets/images/icon-dead.svg"); }
    &.entry-type-unknown   { background-image: url("@/assets/images/icon-unknown.svg"); }

    p.info {
      margin-bottom: 2px;
      color: $gray600;
      font-size: 90%;

      .author {
        margin: 0 15px 0 0;
        font-weight: bold;
      }
    }

    p.message {
      margin-top: 2px;
    }
    p.additional-message {
      margin-top: -13px;
      font-size: .9em;
      font-style: italic;
    }
  }
}
</style>
