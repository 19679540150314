<template>
  <li
    v-touch:swipe.left="toggleRowShift"
    v-touch="hideRowButton"
    :class="{item: true, shifted: rowButtonVisible, completed: (baggedMeals.count == item.count)}">
    <div class="image" :style="`background-image: url('${recipe.thumb_url}')`"></div>
    <div class="info">
      <p v-if="$route.query.debug" class="selectable">
        {{ recipe.recipe_code }}<br>
        {{ preparedMeals }}
      </p>
      <p class="name">{{ item.count}}x <b>{{ recipe.display_name }}</b></p>
      <p class="additional" v-if="recipe.plans_display || item.note">
        <span v-if="recipe.plans_display">{{ recipe.plans_display }}</span>
        <span v-if="item.note">{{ item.note }}</span>
      </p>
    </div>
    <div class="bags">
      <template v-if="baggedMeals.bags">
        <template v-if="baggedMeals.bags[currentBagID]">
          <b>{{ baggedMeals.bags[currentBagID].count }}x</b> in current bag
        </template>
        <span v-for="(bagID) in otherBaggedMeals" :key="`bag-${bagID}`">
          <router-link :to="{ name: 'BaggingBag', params: { date: $route.params.date, id: bagID } }">
          <b>{{ baggedMeals.bags[bagID].count }}x</b> in bag #{{ bagID }}
          </router-link>
        </span>
      </template>
    </div>

    <button
      class="generic-button row-button"
      @click="removeMeal"
      :disabled="!canRemoveFromBag">
      <img src="@/assets/images/btn-delete-white.svg" alt="Remove">
    </button>
  </li>
</template>

<script>
export default {
  emits: ['removeMeal'],
  props: {
    currentBagID: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true
    },
    preparedMeals: {
      type: Array,
      required: true,
    },
    baggedMeals: {
      type: Object,
      required: true,
    },
    neededMeals: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      rowButtonVisible: false,
    }
  },
  computed: {
    recipe() {
      return this.item.recipe;
    },
    otherBaggedMeals() {
      return Object.keys(this.baggedMeals.bags).filter((bagID) => bagID != this.currentBagID);
    },
    canRemoveFromBag() {
      return this.baggedMeals.bags?.[this.currentBagID]?.count > 0;
    }
  },
  methods: {
    toggleRowShift() {
      this.rowButtonVisible = !this.rowButtonVisible;

      if (!this.canRemoveFromBag) {
        setTimeout(() => this.rowButtonVisible = false, 1000);
      }
    },

    hideRowButton(evt) {
      if (evt.target.tagName != "BUTTON") {
        this.rowButtonVisible = false;
      }
    },

    removeMeal() {
      this.$emit('removeMeal', this.item.recipe.recipe_code);
      this.rowButtonVisible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.item {
  margin: 0;
  padding: 1em 1em 1em 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray300;
  display: flex;
  user-select: none;
  position: relative;

  @include generate(transition, 'background-color 2s, transform .25s');

  &:last-child {
    border-bottom: 0;
  }

  &.shifted {
    transform: translateX(-150px);
  }

  &.completed {
    .bags {
      color: green;
      font-weight: bold;
    }

    .image {
      border: 2px solid green;

      /*
      &:after {
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        content: "";
        background: rgba(0,155,0,.5);
        position: absolute;
      }*/
    }
  }

  .image {
    width: 4em;
    height: 4em;
    margin-right: 1em;
    flex-shrink: 0;
    overflow: hidden;
    background: no-repeat center center;
    background-size: cover;
    position: relative;
    border: 0px solid transparent;
    transition: all .5s;

    @include generate(border-radius, 2em);
  }

  .info {
    flex-grow: 1;
    margin: 0 1em 0 0;

    p {
      margin: .5em 0;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    .name {
      font-size: 1.1em;
    }

    .additional {
      color: $gray600;

      span {
        white-space: nowrap;
        display: inline-block;

        &:after {
          margin: 0 .5em;
          content: "·";
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .bags {
    flex-shrink: 0;

    span {
      text-align: right;
      display: block;
    }
  }

  .row-button {
    left: 100%;
    top: 0;
    bottom: 0;
    width: 150px;
    padding: 0;
    margin: 0 0 0 15px;
    font-size: 1em;
    align-self: stretch;
    background: #da0000;
    border: 0;
    overflow: hidden;
    transition: right .25s;
    position: absolute;

    img {
      width: 2.5em;
    }

    &[disabled] {
      color: #222;
      background: #bbb;
    }
  }

}

</style>
