<template>

<div class="main-date-selector">

  <img src="@/assets/images/bg-header.svg" class="background-image" />

  <div class="content">
    <h1>Manage orders menu</h1>
    <p>
      Delivery
      <button @click="openDatePopup">{{ formattedDate }}</button>
    </p>

    <router-link to="/logout/" class="logout" v-if="$store.state.isAuthenticated">
      <img src="@/assets/images/btn-logout-white.svg" alt="Logout">
    </router-link>
  </div>

  <div :class="{'overlay': true, 'visible': datePopupOpen}" @click.self.stop="closeDatePopup">
    <div :class="{'popup': true, 'visible': datePopupOpen}">
      <h3>
        Select delivery date
      </h3>
      <ul class="dates-list">
        <li v-for="(d, idx) in availableDates" :key="`date-${idx}`">
          <button @click="selectDate(d)" :class="{'highlighted': (d == date)}">
            <img src="@/assets/images/icon-calendar.svg" alt="Calendar">
            {{ formatDate(d) }}
          </button>
        </li>
      </ul>
      <button class="close" @click="closeDatePopup"><img src="@/assets/images/btn-close.svg" alt="Close"></button>
    </div>
  </div>
</div>

</template>

<script>
import AppView from '@/mixins/AppView'
import axios from '@/axios';


export default {
  mixins: [AppView],
  props: {
    date: {
      required: true,
      type: String,
    },
  },
  emits: ['changeDate',],
  mounted() {
    this.updateAvailableDates();
  },
  data() {
    return {
      datesUpdatedAt: undefined,
      datePopupOpen: false,
      availableDates: [],
    }
  },
  computed: {
    formattedDate() {
      let d = new Date(this.date);
      return this.formatDate(d);
    }
  },
  methods: {
    formatDate(d) {
      if (typeof(d.getTimezoneOffset) === "undefined") {
        d = new Date(d);
      }

      const offset = d.getTimezoneOffset();
      d = new Date(d.getTime() + (offset * 60000))
      return d.toDateString();
    },

    openDatePopup() {
      this.datePopupOpen = true;
      this.updateAvailableDates();
    },

    closeDatePopup() {
      this.datePopupOpen = false;
    },

    updateAvailableDates() {
      if (this.datesUpdatedAt != null && (new Date() - this.datesUpdatedAt) / 1000 < 60) {
        console.log(`Dates updated ${(new Date() - this.datesUpdatedAt) / 1000} seconds ago, don't update`);
        return;
      }

      this.fetchItems();
    },

    async fetchItems() {
      if (this.cancelFetch !== undefined) {
        this.cancelFetch();
      }

      // this.toggleLoading(true);

      let self = this;

      try {
        var {data} = await axios.get(`/api/dates/`, {cancelToken: new axios.CancelToken(function(c) { self.cancelFetch = c; })});

        if (data.success) {
          this.availableDates = data.items;
          this.datesUpdatedAt = new Date();
        } else {
          data.errors.forEach(e => self.$toast.error(e));
        }
        // this.toggleLoading(false);
      } catch(err) {
        if (!axios.isCancel(err)) {
          [err['message'] || err].forEach(e => this.$toast.error(e));
        }
        // this.toggleLoading(false);
      }
    },

    selectDate(date) {
      this.closeDatePopup();
      // this.$emit("changeDate", date.toJSON().substr(0, 10));
      this.$emit("changeDate", date);
    }
  }
}
</script>

<style lang="scss" scoped>
.main-date-selector {
  margin: -1em -1em 0 -1em;
  color: #fff;
  position: relative;
}

.background-image {
  margin: 0;
  width: 100%;
  aspect-ratio: 375/214;
}

.content {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;

  h1 {
    margin: 0;
  }
  button {
    width: auto;
    padding-top: .5em;
    padding-bottom: .5em;
    padding-left: .5em;
    padding-right: 2em;
    margin-left: .5em;
    color: #fff;
    font-size: 1em;
    font-weight: 300;
    white-space: nowrap;
    background: rgba(255, 255, 255, .2) url("~@/assets/images/dropdown-arrow-white.svg") no-repeat right .5em center;
    background-size: .75em;
    border: 1px solid rgba(255, 255, 255, .5);
  }
}

@media all and (orientation: landscape) {
  .background-image {
    display: none;
  }

  .content {
    padding: 3em 0 7em;
    background: transparent url("~@/assets/images/bg-header.svg") no-repeat center center;
    background-size: cover;
    position: relative;
  }
}

.overlay {
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0,0,0,.5);
  z-index: 9998;
  opacity: 0;
  display: flex;
  position: fixed;

  @include generate(transition, "opacity .5s, left 0s .5s");

  &.visible {
    left: 0;
    opacity: 1;
    @include generate(transition, "opacity .5s, left 0s 0s");
  }
}

.popup {
  min-height: 300px;
  max-width: 80%;
  padding: 2em;
  margin-top: -200px;
  color: $text-color;
  background: #fff;
  position: relative;

  @include generate(transition, "opacity .5s, margin-top .5s");

  &.visible {
    margin-top: 50px;
    opacity: 1;
  }

  h3 {
    margin-top: 0;
    margin-right: 3em;
    line-height: 1em;
  }

  .close {
    top: .5em;
    right: .5em;
    margin: 0;
    padding: 1em;
    width: auto;
    background: transparent;
    border: 0;
    position: absolute;

    img {
      width: 1em;
    }
  }
}

.dates-list {
  max-height: 50vh;
  margin: 0 -2em;
  padding: 0;
  list-style: none;
  overflow-y: auto;

  li {

    button {
      width: 100%;
      padding: 1em 5em 1em 2em;
      color: $text-color;
      font-size: 1.2em;
      font-weight: 400;
      text-align: left;
      vertical-align: middle;
      background: transparent;
      border: 0;
      display: block;
      white-space: nowrap;
      border: 1px solid #fff;

      &.highlighted {
        background: #EDF2F7 url("~@/assets/images/icon-tick.svg") no-repeat right 1.5em center;
        background-size: 1em;
      }

      img {
        margin: -.2em .5em 0 0;
        width: 1em;
        vertical-align: middle;
        display: inline-block;
      }
    }
  }
}

.logout {
  right: 1em;
  top: 2em;
  padding: 0 1em;
  position: absolute;

  img {
    width: 1.75em;
  }
}
</style>
