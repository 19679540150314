<template>
  <tr>
    <td class="bag-id center">
      <router-link :to="{ name: 'BagDetails', params: { id: item.bag_id, backRoute: this.$route.path } }">{{ item.bag_id }}</router-link>
    </td>
    <td class="status center">{{ item.status_display }}</td>
    <td class="action center">
      <button class="return-button" @click="confirmReturn"><img src="@/assets/images/btn-return.svg" alt="Return"></button>
    </td>
  </tr>
</template>

<script>
export default {
  emits: ['confirmReturn'],
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  methods: {
    confirmReturn() {
      this.$emit('confirmReturn', this.item.bag_id)
    },
  }
}
</script>

<style lang="scss" scoped>
  tr {
    user-select: none;

    td {
      background: #fff;

      @include generate(transition, background-color 2s);

      &.status {
        font-weight: normal;
      }

      a {
        color: #000;
      }
    }

    &.highlighted {
      td {
        background: #f8fa93;

        @include generate(transition, background-color .5s);
      }
    }
  }

  .return-button {
    padding: 0;
    border: 0;
    background: transparent;

    img {
      width: 1.25em;
    }
  }
</style>
