import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import '@/registerServiceWorker'
import store from '@/store'

import Vue3TouchEvents from "vue3-touch-events";
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import Toaster from '@meforma/vue-toaster'

import helpers from '@/plugins/helpers'

const app = createApp(App)

const requireComponent = require.context(
  './components',
  true,
  /\.vue$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  app.component(
    componentName, componentConfig.default || componentConfig
  )
})

app.use(router)
app.use(store)
app.use(helpers)
app.use(Vue3TouchEvents)
app.use(Toaster, {position: 'top'})
app.mount('#app')
