<template>
  <div v-long-press="500" @long-press="toggleInfo" class="info">
    <button class="btn-reload" v-if="showBuildInfo" @click="reload">Reload</button>
    <p class="copyright" v-if="!showBuildInfo">&copy; 2023 Y-Verge</p>
    <build-info v-if="showBuildInfo" />
  </div>
</template>

<script>
import LongPress from '@/directives/long-press/';

export default {
  directives: {
    'long-press': LongPress,
  },
  data() {
    return {
      showBuildInfo: false,
    }
  },
  methods: {
    toggleInfo() {
      if (this.$route.query.debug)  {
        this.$route.query.debug = false;
      } else {
        this.$route.query.debug = true;
      }

      this.showBuildInfo = !this.showBuildInfo;
    },

    reload() {
      window.location.reload(true);
    },
  }
}
</script>

<style lang="scss" scoped>
.info {
  bottom: 0;
  right: 0;
  left: 0;
  padding: 1em;
  color: #888;
  text-align: right;
  position: absolute;
  user-select: none;
}



.build-info {
  &:before {
    color: #bbb;
    content: "Build: ";
  }
}

.btn-reload {
  left: 0;
  bottom: 0;
  width: auto;
  padding: 1em;
  color: $link-color;
  font-size: 1em;
  font-weight: 400;
  border: 0;
  background: transparent;
  position: absolute;
}
</style>
