<template>
  <p>
    <label for="screen-reader">Scanner Helper </label>
    <input
      type="text"
      autocapitalize="off"
      @keyup.enter="submit"
      v-model="input"
      name="screen-reader" />
  </p>
</template>

<script>
export default {
  emits: ['scannerRead'],
  data() {
    return {
      input: '',
    }
  },
  methods: {
    submit() {
      this.$emit('scannerRead', this.input.trim());
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  align-items: center;
  justify-content: space-between;
  display: flex;

  label {
    margin-right: 1em;
  }

  input {
    max-width: 300px;
    flex-grow: 1;
    margin-left: 0;
    margin-right: auto;
  }
}
</style>
