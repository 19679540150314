<template>
  <div :class="{'nav-tile': true, disabled: !active}">
    <router-link :to="to">
      <img :src="icon" :alt="title" class="icon">
      <span class="title">{{ title }}</span>
      <span :class="{subtitle: true, loading: loading || itemsCount == null}" v-if="subtitle">{{ subtitle }}</span>
      <span :class="{subtitle: true, loading: loading || itemsCount == null}" v-else-if="itemName">{{ itemsCount }} {{ itemName }}<template v-if="itemsCount > 1 || itemsCount == 0">s</template></span>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    itemsCount: {
      type: Number,
      required: false,
    },
    itemName: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.nav-tile {
  flex-basis: 100%;
  margin: 0 .5em;
  background: #fff;
  border: 1px solid $gray300;
  position: relative;
  box-shadow: 0 1em 1em rgba(15, 13, 102, 0.04);

  &.disabled {
    opacity: .4;
    pointer-events: none;
  }

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  a {
    padding: 1em;
    color: $text-color;
    display: block;
  }

  .icon {
    margin: 0 0 .5em;
    width: 2.5em;
  }

  .title {
    font-weight: 600;
    display: block;
  }
  .subtitle {
    min-width: 3em;
    min-height: 1em;
    color: $gray600;
    display: inline-block;

    @include loading;
  }
}
</style>
