<template>
  <li class="item">
    <span :class="statusClass"></span>

    <div class="info">
      <p class="customers">{{ item.customers.map(c => $formatName(c)).join(', ') }}</p>
      <p class="details">
        <span>Bag: {{ item.bag_id }}</span>
        <span>Meals: {{ item.meal_count }}</span>
      </p>
      <p class="details">
        <span>Orders: {{ item.orders.map((o) => `#${o}`).join(', ') }}</span>
      </p>
    </div>

    <!-- <router-link :to="{ name: 'BaggingBag', params: { date, id: bag_id } }">
      <p class="bag-id">Bag: {{ bag_id }}</p>
      <p class="info">
        <span>Orders: {{ orders.map((o) => `#${o}`).join(', ') }}</span>
        <span>No. of meals: {{ meal_count }}</span>
      </p>
    </router-link> -->
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    // bag_id: {
    //   type: String,
    //   required: true,
    // },
    // meal_count: {
    //   type: Number,
    //   required: true,
    // },
    // orders: {
    //   type: Array,
    //   required: true,
    // },
    // customers: {
    //   type: Array,
    //   required: true,
    // },
    // status: {
    //   type: String,
    //   required: true,
    // },
    // status_display: {
    //   type: String,
    //   required: true,
    // },
    // date: {
    //   type: String,
    //   required: true,
    // }
  },
  computed: {
    statusClass() {
      return ['status-marker', `status-${this.item.bagging_status}`];
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.item {
  margin: .5em 0;
  border: 1px solid $gray300;
  box-shadow: 0 1em 1em rgba(15, 13, 102, 0.04);
  background: #fff;
  position: relative;

  @include generate(transition, background-color 2s);

  a,
  .info {
    padding: 1em 3.7em 1em 1em;
    color: $text-color;
    display: block;
  }

  &.show-arrow {
    a, .info {
      background: transparent url("~@/assets/images/btn-next.svg") no-repeat center right 1.5em;
      background-size: .7em;
    }
  }

  &.status-finished .info,
  &.status-finished .details {
    color: #080;
  }

  .status-marker {
    left: 0;
    top: 1em;
    bottom: 1em;
    width: .15em;
    background: #120ce8;
    position: absolute;

    &.status-in-progress { background: #fd0; }
    &.status-finished { background: #0c0; }
  }

  p {
    margin: .5em 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    &.bag-id,
    &.customers {
      margin-bottom: .5em;
      font-size: 1.1em;
      font-weight: 600;
    }
    &.details {
      margin: 0;
      color: $gray600;

      span {
        white-space: nowrap;

        &:after {
          margin: 0 .5em;
          content: "·";
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
</style>
