<template>
  <nav>
    <div class="tile" v-for="(item, idx) in menuItems" :key="idx">
      <div class="spanner">
        <router-link :to="item[1]">{{ item[0] }}</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  mounted() {
  },
  data() {
    return {
      menuItems: [
        ["Packing", "/packing/"],
        ["Traying", "/traying/"],
        ["Bagging", "/bagging/"],
        ["Pre Delivery", "/predelivery/"],
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
nav {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
  display: flex;
  position: absolute;

  .tile {
    width: 50%;
    margin: 0;
    padding: 20px;

    .spanner {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
    }
  }

  a {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: $accent-color-inverse;
    font-size: 5vw;
    background: $accent-color;
    display: flex;
    position: absolute;
  }
}
</style>
