export default {
  emits: ['toggleLoading',],
  data() {
    return {
      loading: false,
      cancelFetch: undefined,
    }
  },
  beforeUnmount() {
    if (this.cancelFetch !== undefined) {
      this.cancelFetch();
    }
  },
  methods: {
    toggleLoading(status) {
      // console.log(`Toggle loading ${status}`)
      this.loading = status;
      this.$emit('toggleLoading', status);
    },
  }
}
