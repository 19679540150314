<template>
  <div :class="{ 'collapsible-box': true, collapsed: collapsed,}">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    collapsed: {
      type: Boolean,
      required: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsible-box {
  max-height: 1000px;
  overflow-y: clip;
  transition: max-height .25s;

  &.collapsed {
    max-height: 0 !important;
  }
}
</style>
