<template>
  <tr
    v-touch:swipe.left="toggleRowShift"
    v-touch="hideRowButton"
    :class="{'dispatch-button-visible': rowButtonVisible}">
    <td class="select-multiple hide-small" v-if="selectMultiple">
      <input type="checkbox" :checked="selected" @change="updateSelected" />
    </td>
    <td class="customers">
      <template v-for="(customer, idx) in item.customers" :key="`customer-${idx}`">
        {{ customer }}<br>
      </template>
    </td>
    <td class="bag-id center">
      {{ item.bag_id }}
      <span v-if="item.override_route_code != '' && item.override_route_code != item.route_code">
        ({{ item.route_code}})
      </span>
      <!-- <b class="overriden" v-if="item.override_route_code != ''">*</b> -->
    </td>
    <td class="orders center">{{ item.orders.map(oid => `#${oid}`).join(', ') }}</td>
    <td class="meal-count center hide-small">{{ item.meal_count }}</td>
    <td class="status center">
      {{ item.status_display }}

      <button
        class="generic-button row-button"
        @click="dispatchItem"
        :disabled="item.status != 'available'"
        v-if="allowDispatch">
        Dispatch
      </button>
      <button
        class="generic-button row-button"
        @click="rerouteItem"
        v-if="allowOverride">
        Re-route
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  emits: ['scannerRead', 'rerouteItem', 'update:selected'],
  props: {
    item: {
      type: Object,
      required: true,
    },
    allowDispatch: {
      type: Boolean,
      required: false,
    },
    allowOverride: {
      type: Boolean,
      required: false,
    },
    selectMultiple: {
      type: Boolean,
      required: false,
    },
    selected: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      rowButtonVisible: false,
      // selectedValue: this.$props.selected,
    }
  },
  methods: {
    dispatchItem() {
      this.$emit("scannerRead", this.item.bag_id);
      this.rowButtonVisible = false;
    },

    rerouteItem() {
      this.$emit("rerouteItem", this.item.bag_id);
      this.rowButtonVisible = false;
    },

    toggleRowShift() {
      if (!this.allowDispatch && !this.allowOverride) return;

      this.rowButtonVisible = !this.rowButtonVisible;
      if (this.allowDispatch && this.item.status != "available") {
        setTimeout(() => this.rowButtonVisible = false, 1000);
      }
    },

    hideRowButton(evt) {
      if (evt.target.tagName != "BUTTON") {
        this.rowButtonVisible = false;
      }
    },

    updateSelected(evt) {
      this.$emit('update:selected', evt.target.checked)
    },
  }
}
</script>

<style lang="scss" scoped>
tr {
  user-select: none;
  position: relative;
  @include generate(transition, transform .25s);

  td {
    background: #fff;
    position: relative;

    @include generate(transition, background-color 2s);
  }

  &.highlighted {
    td {
      background: #f8fa93;

      @include generate(transition, background-color .5s);
    }
  }

  &.dispatch-button-visible {
    transform: translateX(-150px);
  }

}
.overriden {
  color: red;
}

.row-button {
  left: 100%;
  top: 0;
  bottom: 0;
  width: 150px;
  padding: 0;
  margin: 0;
  font-size: 1em;
  align-self: stretch;
  background: #00aa00;
  border: 0;
  overflow: hidden;
  transition: right .25s;
  position: absolute;

  &[disabled] {
    color: #222;
    background: #bbb;
  }
}

</style>
