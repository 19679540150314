<template>
  <li class="item">
    <span :class="statusClass"></span>
    <p v-if="$route.query.debug" class="selectable">{{ order_code }}</p>
    <router-link :to="{ name: 'TrayedOrder', params: { date, id: order_code } }">
      <p class="customer-name">{{ $formatName(customer_name) }}</p>
      <p class="info">
        <span>Order: #{{ order_code }}</span>
        <span>{{ plan }}</span>
        <span>{{ meal_count }} item<template v-if="meal_count > 1">s</template></span>
      </p>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    customer_name: {
      type: String,
      required: true,
    },
    plan: {
      type: String,
      required: true,
    },
    order_code: {
      type: String,
      required: true,
    },
    meal_count: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    status_display: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    }
  },
  computed: {
    statusClass() {
      const css = {
        'status-marker': true,
      }
      css[`status-${this.status}`] = true
      return css;
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.item {
  margin: .5em 0;
  border: 1px solid $gray300;
  box-shadow: 0 1em 1em rgba(15, 13, 102, 0.04);
  background: #fff;
  position: relative;

  @include generate(transition, background-color 2s);

  a {
    padding: 1em 3.7em 1em 1em;
    color: $text-color;
    background: transparent url("~@/assets/images/btn-next.svg") no-repeat center right 1.5em;
    background-size: .7em;
    display: block;
  }

  .status-marker {
    left: 0;
    top: 1em;
    bottom: 1em;
    width: .15em;
    background: #120ce8;
    position: absolute;

    &.status-new { background: #120ce8; }
    &.status-trayed { background: #00a21a; }
  }

  p {
    margin: .5em 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    &.customer-name {
      font-size: 1.1em;
      font-weight: 600;
    }
    &.info {
      color: $gray600;

      span {
        white-space: nowrap;

        &:after {
          margin: 0 .5em;
          content: "·";
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
</style>
