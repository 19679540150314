<template>
  <li
    v-touch:swipe.left="toggleRemoveButton"
    v-touch="hideRemoveButton"
    :class="{item: true, 'remove-button-visible': removeButtonVisible}">
    <div class="image" :style="`background-image: url('${recipe.thumb_url}')`"></div>
    <div class="info">
      <p v-if="$route.query.debug" class="selectable">{{ recipe.recipe_code }}</p>
      <div class="text">
        <p class="name">{{ recipe.display_name }} <span class="plans" v-if="recipe.plans_display">({{ recipe.plans_display }})</span></p>
        <p class="count">{{ scannedCount }} / {{ item.count }}</p>
      </div>
      <progress :max="item.count" :value="scannedCount" :class="progressClass(scannedCount / item.count)">
        {{ scannedCount }}/{{ item.count }}
      </progress>
    </div>
    <button
      :class="{remove: true, visible: removeButtonVisible, 'generic-button': true}"
      @click="removeItemPrompt"
      v-if="scannedCount">
      <img src="@/assets/images/btn-delete-white.svg" alt="Remove">
    </button>
  </li>
</template>

<script>
export default {
  emits: ['removeScanned'],
  props: {
    item: {
      type: Object,
      required: true
    },
    scannedCount: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      removeButtonVisible: false,
    }
  },
  computed: {
    recipe() {
      return this.item.recipe;
    }
  },
  methods: {
    removeItemPrompt() {
      if (this.scannedCount) {
        this.$emit('removeScanned', this.recipe.recipe_code);
        this.removeButtonVisible = false;
      }
    },

    toggleRemoveButton() {
      this.removeButtonVisible = !this.removeButtonVisible;
    },

    hideRemoveButton(evt) {
      if (evt.target.tagName != "BUTTON") {
        this.removeButtonVisible = false;
      }
    },

    progressClass(value) {
      if (value > 1) {
        return "overscanned";
      } else if (value <= 0) {
        return "zero";
      } else if (value == 1) {
        return "complete";
      } else {
        return "in-progress";
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.item {
  margin: 0 0 1em 0;
  padding: 1em;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid $gray300;
  box-shadow: 0 1em 1em rgba(15, 13, 102, 0.04);
  background: #fff;
  display: flex;
  position: relative;
  user-select: none;
  overflow: hidden;

  @include generate(transition, background-color 2s);


  &.highlighted {
    background: #f8fa93;

    @include generate(transition, background-color .5s);
  }


  .image {
    width: 4em;
    height: 4em;
    margin-right: 1em;
    flex-shrink: 0;
    overflow: hidden;
    border: no-repeat center center;
    background-size: cover;
    transition: transform .25s;

    @include generate(border-radius, 2em);
  }
  .info {
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    display: flex;
    transition: transform .25s;

    .text {
      margin-bottom: .5em;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .name {
        margin: 0 1em 0 0;;
        font-size: 1.2em;
        font-weight: 600;

        .plans {
          font-size: .9em;
          font-weight: normal;
          display: block;
        }
      }
      .count {
        padding-left: 1.3em;
        flex-shrink: 0;
        margin: 0;
        color: $gray600;
        background: transparent url("~@/assets/images/icon-food-container.svg") no-repeat left center;
        background-size: 1em;
      }
    }

  }

  progress {
    height: .75em;
  }


/*  .remove {
    right: 1em;
    top: 1em;
    padding: 0 1em;
    width: auto;
    color: #fff;
    line-height: 2em;
    background: #da0000;
    border: .1em solid #fff;
    position: absolute;
    box-shadow: .1em .1em .3em rgba(0,0,0,.5);

    @include generate(border-radius, 1em);
  }*/

  .remove {
    right: -150px;
    top: 0;
    bottom: 0;
    width: 150px;
    padding: 0;
    align-self: stretch;
    background: #da0000;
    border: 0;
    overflow: hidden;
    transition: right .25s;
    position: absolute;

    &.visible {
      right: 0px;
    }

    img {
      width: 2.5em;
    }
  }

  &.remove-button-visible {
    .image,
    .info {
      transform: translateX(-150px);
    }
  }
}
</style>
