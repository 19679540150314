<template>
  <div id="top-bar">
    <div class="left">
      <button @click="backAction" v-if="backAction">
        <img src="@/assets/images/btn-back.svg" alt="Back">
      </button>
      <router-link class="back" :to="backRoute" v-else-if="backRoute">
        <img src="@/assets/images/btn-back.svg" alt="Back">
      </router-link>
      <router-link :to="homeURL" v-else-if="homeURL">
        <img src="@/assets/images/btn-back.svg" alt="Home">
      </router-link>
    </div>
    <div class="center">
      <h1 class="title">{{ title }}</h1>
      <p class="subtitle" v-if="subtitle">{{ subtitle }}</p>
    </div>
    <div class="right">
      <slot name="right" />
      <router-link to="/logout/" class="logout" v-if="$store.state.isAuthenticated">
        Logout
        <img src="@/assets/images/btn-logout.svg" alt="Logout">
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      required: false,
      type: String,
    },
    backAction: {
      required: false,
      type: Function,
    },
    backRoute: {
      required: false,
      type: Object,
    },
    homeURL: {
      required: false,
      type: String,
    }
  },
}
</script>

<style lang="scss" scoped>
#top-bar {
  margin: 0;
  padding: 1em 0em;
  user-select: none;
  flex-shrink: 0;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0 1em 1em rgba(15, 13, 102, 0.04);
  display: flex;
  position: relative;

  .center {
    flex-grow: 3;
    flex-basis: 0;
    text-align: center;
    position: relative;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .left,
  .right {
    margin-left: 1em;
    flex-grow: 1;
    flex-basis: 0;
    position: relative;
    z-index: 2;
    flex-direction: row;
    align-items: stretch;
    display: flex;
  }
  .right {
    margin-left: 0;
    margin-right: 1em;
    justify-content: flex-end;
  }

  .title {
    margin: 0;
    padding: 0;
    font-size: 1.2em;
    font-weight: 600;
  }
  .subtitle {
    margin: 0;
    padding: 0;
    color: $gray600;
  }

  a {
    color: #666;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  button {
    min-width: 3em;
    min-height: 3em;
    width: auto;
    padding: 0;
    font-size: 1em;
    justify-content: center;
    align-items: center;
    display: flex;
    background: transparent;
    border: 0;

    img {
      width: 1.75em;
      margin: 0;
    }
  }

  .logout {
    padding: 0 1em;

    img {
      width: 1.75em;
      margin: 0 0 0 0.5em;
    }
  }

  .back {
    min-width: 3em;
    min-height: 3em;

    img {
      width: 1.75em;
      margin: 0;
    }
  }
}
</style>
