import { createStore } from 'vuex'

export default createStore({
  state: {
    isAuthenticated: false,
    user: null,
    date: undefined,
    home: {},
    bagReportSortColumn: '+last_order',
  },

  getters: {
    inGroup: (state) => (group) => (state.user && state.user.groups && state.user.groups.indexOf(group) > -1),
    isProduction: (state, getters) => getters.inGroup('production'),
    isDriver: (state, getters) => getters.inGroup('drivers'),

  },

  mutations: {
    init(state) {
      if (localStorage.getItem('user')) {
        try {
          state.user = JSON.parse(localStorage.getItem('user'));
        } catch {
          state.user = null;
        }
      }
      state.isAuthenticated = localStorage.getItem('isAuthenticated') || false;
      state.date = localStorage.getItem('date') || undefined;
      state.bagReportSortColumn = localStorage.getItem('bagReportSortColumn') || '+last_order__date';

      if (localStorage.getItem('home')) {
        try {
          state.home = JSON.parse(localStorage.getItem('home'));
        } catch {
          state.home = {};
        }
      }
    },

    updateAuth(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;

      if (isAuthenticated) {
        localStorage.setItem('isAuthenticated', true);
      } else {
        localStorage.removeItem('isAuthenticated');
      }

    },

    updateUser(state, user) {
      state.user = user;

      if (user == null) {
        localStorage.removeItem('user');
      } else {
        localStorage.setItem('user', JSON.stringify(user));
      }
    },

    updateDate(state, date) {
      state.date = date;
      localStorage.setItem('date', date);
    },

    updateHome(state, data) {
      state.home = data;
      localStorage.setItem('home', JSON.stringify(data));
    },

    updateBagReportSortColumn(state, data) {
      state.bagReportSortColumn = data;
      localStorage.setItem('bagReportSortColumn', data);
    },
  },
  actions: {
  },
  modules: {
  }
})
