<template>
  <ul class="side-list">
    <slot />
  </ul>
</template>

<script>
export default {
}
</script>


<style lang="scss" scoped>
.side-list {
  width: 100%;
  margin: 20px 0;
  padding: 0;
  justify-content: space-between;
  align-items: stretch;
  list-style: none;
  display: flex;
}
</style>

<style lang="scss">
.side-list {
  > li {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding: 0 1em;
    border-left: 1px solid $gray300;


    &:first-child {
      border-left: 0;
    }
  }
}
</style>
