import axios from "axios";
import router from '@/router';
import store from '@/store';

const options = {
  // baseURL: process.env.VUE_APP_API_BASE,
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
};

const instance = axios.create(options);
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;



instance.interceptors.response.use(function(response) {
  return response;
}, function(error) {
  if (error.response && error.response.status == 401) {
    store.commit('updateAuth', false);
    store.commit('updateUser', null);
    router.push({name: 'Login'});
  }
  return Promise.reject(error);
});

export default instance;
