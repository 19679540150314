<template>
  <div class="pagination" v-if="maxPage > 1">
    <button :disabled="page <= 1" @click="previousPage">&larr;</button>

    <span class="current">
      {{ page }} of {{ maxPage }}
    </span>

    <button :disabled="page >= maxPage" @click="nextPage">&rarr;</button>
  </div>
</template>

<script>
export default {
  emits: ['previous-page', 'next-page'],
  props: {
    page: {
      type: Number,
      required: true,
    },
    maxPage: {
      type: Number,
      required: true,
    }
  },
  methods: {
    previousPage: function() {
      this.$emit('previous-page');
    },
    nextPage: function() {
      this.$emit('next-page');
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  margin: 1em 0;
  text-align: center;

  button {
    margin: 0 5px;
    padding: 15px;
    color: #2d3748;
    font-weight: bold;
    cursor: pointer;
    border: 0;
    background: transparent;

    &[disabled] {
      opacity: .25;
      cursor: default;
    }
  }
}
</style>
