<template>
  <tr>
    <th class="select-multiple hide-small" v-if="selectMultiple">
      <input type="checkbox" :checked="allSelected" @change="toggleAllSelected">
    </th>
    <th class="customers">
      <slot>Customer</slot>
    </th>
    <th class="bag-id center">Bag</th>
    <th class="orders center">Orders</th>
    <th class="meal-count center hide-small">Items</th>
    <th class="status center">Status</th>
  </tr>
</template>

<script>
export default {
  emits: ['toggleAllSelected'],
  props: {
    selectMultiple: {
      type: Boolean,
      required: false,
    },

    allSelected: {
      type: Boolean,
      required: false,
    },
  },

  methods: {
    toggleAllSelected(evt) {
      this.$emit('toggleAllSelected', evt.target.checked);
    }
  }
}
</script>
