export default {
  install: (app) => {
    app.config.globalProperties.$pluralize = (singular, plural, count) => {
      return count == 1 ? singular : plural;
    }

    app.config.globalProperties.$formatDate = (date, format) => {
      if (typeof(date) === 'undefined') return date;

      if (typeof(date.toISOString) != "function") {
        let d = Date.parse(date);
        if (isNaN(d)) {
          return date;
        }
        d = new Date(d);
        const offset = d.getTimezoneOffset();
        date = new Date(d.getTime() + (offset * 60000))
      }

      if (typeof(date.toISOString) === "function") {
        if (format == "locale") {
          return date.toLocaleString();
        }
        return date.toDateString();
      }

      return date;
    }

    app.config.globalProperties.$formatName = (value) => {
      return value;
      if (typeof(value) === 'string') {
        if (value.toLowerCase() == value || value.toUpperCase() == value) {
          let upper = true;
          let newValue = "";
          for (let i = 0, l = value.length; i < l; i++) {
            if (value[i] == " " || value[i] == "-" || value[i] == "'") {
              upper = true;
              newValue += value[i];
              continue
            }
            newValue += upper ? value[i].toUpperCase() : value[i].toLowerCase()
            upper = false;
          }

          return newValue;
        }
      }

      return value;
    }

    app.config.globalProperties.$formatEmail = (value) => {
      if (typeof(value) === 'string') {
        return value.toLowerCase();
      }

      return value;
    }

  }
}
