const longPress = {
  beforeMount(el, { value }, vnode) {
    if (typeof(value) !== "number") {
      console.log("Long press value is not a number. Should be time in ms");
    }

    const timeout = value;
    let timer = null;
    const longPressStart = (evt) => {
      // console.log("longPressStart");
      if (evt.type === 'click' && evt.button !== 0) {
        return;
      }

      if (timer === null) {
        timer = setTimeout(() => {
          // console.log("long press timer fire");
          if (vnode.props && typeof(vnode.props.onLongPress) == "function") {
            vnode.props.onLongPress()
          }
          timer = null;
          // if (typeof(instance.longPress) == "function") {
          //   instance.longPress()
          // }
        }, timeout);
      }
    }


    const longPressCancel = () => {
      if (timer !== null) {
        clearTimeout(timer);
        timer = null;
      }
    }

    ['mousedown', 'touchstart'].forEach(evt => el.addEventListener(evt, longPressStart));
    ['click', 'mouseout', 'touchend', 'touchcancel'].forEach(evt => el.addEventListener(evt, longPressCancel));
  },

}


export default longPress;
