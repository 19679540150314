<template>
  <div class="controls">
    <select :value="sortColumn" @change="updateSorting">
      <template v-for="(label, value, idx) in sortOptions" :key="`sorting-${idx}`">
        <option :value="`+${value}`">{{ label }} &uarr;</option>
        <option :value="`-${value}`">{{ label }} &darr;</option>
      </template>
    </select>

    <button class="generic-button" @click="exportReport">Export</button>
  </div>
</template>

<script>
export default {
  emits: ['update:sortColumn'],
  props: {
    sortColumn: {
      type: String,
      required: true,
    },
    sortOptions: {
      type: Object,
      required: true,
    }
  },

  methods: {
    updateSorting(evt) {
      this.$emit('update:sortColumn', evt.target.value);
      this.$store.commit('updateBagReportSortColumn', evt.target.value);
    },

    exportReport() {
      const url = `/api/bags/report/customers/csv/`;
      const qs = new URLSearchParams({sort: this.sortColumn});
      window.open(`${url}?${qs}`, '_blank');
    }
  },
}
</script>

<style lang="scss" scoped>
.controls {
  margin: -1em -1em 0;
  padding: 1em;
  flex-direction: row;
  justify-content: space-between;
  background: $gray400;
  display: flex;

  select {
    width: auto;
  }

  /*button {
    width: auto;
    padding-left: 1em;
    padding-right: 1em;
    color: #fff;
    font-size: 1em;
    font-weight: normal;
    border: 1px solid #007115;
    background: #00b422;
  }*/
  button {
    width: auto;
  }
}
</style>
