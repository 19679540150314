<template>
  <div class="search-bar">
    <input
      class="search-term"
      placeholder="Search"
      type="text"
      autocapitalize="off"
      autokeyhint="search"
      :value="searchTerm"
      @input="searchTermInput"
      @change="searchTermChanged" />
  </div>
</template>

<script>
export default {
  emits: ['update:searchTerm'],
  props: {
    searchTerm: {
      type: String,
      required: true,
    },
    updateOnInput: {
      type: Boolean,
      required: false,
    }
  },
  methods: {
    searchTermChanged(evt) {
      this.$emit('update:searchTerm', evt.target.value)
      evt.target.blur();
    },

    searchTermInput(evt) {
      if (this.updateOnInput) {
        this.$emit('update:searchTerm', evt.target.value)
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.search-bar {
  margin: 0 -1em;
  padding: .5em;
  background: $gray300;

  .search-term {
    max-width: 100%;
    padding-top: .5em;
    padding-bottom: .5em;
    line-height: 2.566em;
  }
}
</style>
