<template>
  <li class="item">
    <div class="info">
      <div class="text">
        <p class="name">
          {{ name }}
        </p>

        <p class="count">{{ scannedCount }}/{{ count }}</p>
      </div>

      <progress :max="count" :value="scannedCount" :class="progressClass(scannedCount / count)">
        {{ scannedCount }}/{{ count }}
      </progress>

    </div>
  </li>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    scannedCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    count() {
      return Object.keys(this.item).length;
    },
  },
  methods: {
    progressClass(value) {
      if (value > 1) {
        return "complete";
      } else if (value <= 0) {
        return "zero";
      } else if (value == 1) {
        return "complete";
      } else {
        return "in-progress";
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.item {
  margin: 0 0 1em 0;
  padding: 1em;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid $gray300;
  box-shadow: 0 1em 1em rgba(15, 13, 102, 0.04);
  background: #fff;
  display: flex;
  position: relative;
  user-select: none;

  @include generate(transition, background-color 2s);


  &.highlighted {
    background: #f8fa93;

    @include generate(transition, background-color .5s);
  }


  .image {
    width: 4em;
    height: 4em;
    margin-right: 1em;
    flex-shrink: 0;
    overflow: hidden;
    border: no-repeat center center;
    background-size: cover;

    @include generate(border-radius, 2em);
  }
  .info {
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    display: flex;

    .text {
      margin-bottom: .5em;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .name {
        margin: 0 1em 0 0;;
        font-size: 1.2em;
        font-weight: 600;

        .variants {
          font-size: .9em;
          font-weight: normal;
          display: block;
        }
      }
      .count {
        padding-left: 1.3em;
        flex-shrink: 0;
        margin: 0;
        color: $gray600;
        background: transparent url("~@/assets/images/icon-food-container.svg") no-repeat left center;
        background-size: 1em;
      }
    }

  }

  progress {
    height: .75em;
  }


  .remove {
    left: -.5em;
    top: 50%;
    width: 1em;
    height: 1em;
    margin-top: -0.75em;
    padding: 0;
    color: #fff;
    font-size: 2em;
    font-family: sans-serif;
    line-height: .5em;
    background: #da0000;
    border: .1em solid #fff;
    position: absolute;
    box-shadow: .1em .1em .3em rgba(0,0,0,.5);

    @include generate(border-radius, 1em);
  }
}
</style>
