import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Root',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/Home.vue"),
  },

  {
    path: '/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/Home.vue"),
  },

  {
    path: "/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/meal-count/",
    name: 'MealCount',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/MealCount.vue"),
  },


  {
    path: '/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/traying/',
    name: 'Traying',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/Traying.vue"),
  },
  {
    path: '/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/traying/:id([0-9]*)/',
    name: 'TrayedOrder',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/TrayedOrder.vue"),
  },


  {
    path: '/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/pre-delivery/',
    name: 'PreDelivery',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/PreDelivery.vue"),
  },
  {
    path: '/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/pre-delivery/search/',
    name: 'PreDeliverySearch',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/PreDeliveryDetails.vue"),
  },
  {
    path: '/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/pre-delivery/:routeCode([a-zA-Z]+)/',
    name: 'PreDeliveryRoute',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/PreDeliveryDetails.vue"),
  },



  {
    path: '/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/bagging/',
    name: 'Bagging',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/Bagging.vue"),
  },
  {
    path: '/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/bagging/:id([0-9]*)/',
    name: 'BaggedOrder',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/BaggedOrder.vue"),
  },

  {
    path: '/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/bagging/bag/:id([0-9]*)/',
    name: 'BaggingBag',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/BaggingBag.vue"),
  },



  {
    path: '/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/dispatch/',
    name: 'BagDispatch',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/BagDispatch.vue"),
  },
  {
    path: '/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/dispatch/:routeCode([a-zA-Z]+)/',
    name: 'BagDispatchRoute',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/BagDispatchRoute.vue"),
  },

  {
    path: '/return/',
    name: 'BagReturn',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/BagReturn.vue"),
  },


  {
    path: '/bag/:id([0-9]*)/',
    name: 'BagDetails',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/BagDetails.vue"),
  },


  {
    path: '/report/customers/',
    name: 'BagReportByCustomer',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/BagReportByCustomer.vue"),
  },

  {
    path: '/report/bags/',
    name: 'BagReportByBag',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/BagReportByBag.vue"),
  },

  {
    path: '/customer/:email([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,})/',
    name: 'Customer',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/Customer.vue"),
  },


  {
    path: '/scanner/',
    name: 'ScannerDebugger',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/ScannerDebugger.vue"),
  },


  // {
  //   path: "/:date([0-9]{4}-[0-9]{2}-[0-9]{2})/galley-ids/",
  //   name: 'GalleyIDs',
  //   component: () => import(/* webpackChunkName: "views"*/ "@/views/GalleyIDs.vue"),
  // },



  {
    path: '/login/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/Login.vue"),
  },
  {
    path: '/logout/',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "views"*/ "@/views/Logout.vue"),
  },

  {
    path: '/static/app',
    redirect: '/',
  },

  {
    path: "/:catchAll(.*)",
    component: () => import(/* webpackChunkName: "views"*/ "@/views/NotFound.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, from) => {
  const fromDepth = from.path.split('/').length;
  const toDepth = to.path.split('/').length;

  to.meta.transitionName =
    (from.name == "Home" && to.name == "Home") ? 'none' :
    (from.path == "/" || toDepth == fromDepth) ? 'fade' :
    (toDepth < fromDepth) ? 'slide-out' :
    'slide-in';

  if (typeof(to.query.debug) === "undefined") {
    to.query.debug = from.query.debug;
  }
})

export default router
