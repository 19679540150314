<template>
  <tr>
    <th class="bag-id center">Bag ID</th>
    <th class="status center">Status</th>
    <th class="action center">Return</th>
  </tr>
</template>

<style lang="scss" scoped>
.action {
  padding-left: 0;
  padding-right: 0;
}
</style>
