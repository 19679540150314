<template>
  <h3>Orders</h3>
  <FetchErrors
    :errors="errors"
    @tryAgain="fetchItems"
    v-if="errors.length" />

  <ul v-if="items.length" class="orders">
    <li v-for="(entry, idx) in items" :key="`entry-${idx}`">
      <b>{{ entry.customer_name }}</b> ({{ entry.email }})<br>
      <span class="field">Date: <b>{{ entry.date }}</b></span>
      <span class="field">Plan: <b>{{ entry.plan }}</b></span>
      <span class="field">Route: <b>{{ entry.route_code }}</b></span>
    </li>
  </ul>

  <Pagination
    @previous-page="previousPage"
    @next-page="nextPage"
    :page="page"
    :max-page="maxPage" />
</template>

<script>
import axios from '@/axios';

export default {
  emits: ['toggleLoading'],
  props: {
    bagID: null,
  },
  data() {
    return {
      page: 1,
      maxPage: 1,
      totalItems: 0,
      loading: false,
      cancelFetch: undefined,
      errors: [],
      items: [],
    }
  },
  mounted: function() {
    this.fetchItems();
  },
  methods: {
    previousPage: function() {
      this.page = Math.max(1, this.page - 1)
      this.fetchItems();
    },
    nextPage: function() {
      this.page = Math.min(this.maxPage, this.page + 1)
      this.fetchItems();
    },

    async fetchItems() {
      if (this.cancelFetch !== undefined) {
        this.cancelFetch();
      }

      this.errors = [];
      this.toggleLoading(true);

      let self = this;

      try {
        var {data} = await axios.get(`/api/bag/${this.bagID}/orders/?page=${this.page}`, {cancelToken: new axios.CancelToken(function(c) { self.cancelFetch = c; })});

        if (data.success) {
          this.page = data.page;
          this.maxPage = data.max_page;
          this.totalItems = data.total_items;
          this.items = data.items;
          this.errors = [];
        } else {
          this.items = {};
          this.errors = data.errors;
        }
        this.toggleLoading(false);
      } catch(err) {
        if (!axios.isCancel(err)) {
          this.errors = [err['message'] || err];
        }
        this.toggleLoading(false);
      }
    },

    toggleLoading(status) {
      this.loading = status
      this.$emit('toggleLoading', status);
    },
  }
}
</script>

<style lang="scss">
.orders {
  margin: 1em -1em;
  padding: 0;
  list-style: none;
  background: #fefefe;
  border-bottom: 1px solid $border-color;

  li {
    padding: 1em;
    border-top: 1px solid $border-color;

    .field {
      margin-right: 15px;
      white-space: nowrap;
    }
  }
}
</style>
