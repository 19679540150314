<template>
  <p class="error" v-for="(error, idx) in errors" :key="'error-' + idx">{{ error }}</p>
  <p><button class="generic-button" @click="$emit('tryAgain')">Try Again</button></p>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
  emits: ['tryAgain'],
}
</script>
